body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Archive Garamond';
  font-style: italic;
	font-size: 14px;
  color: #9c8f86;
	margin: 0 auto;
	padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Delinda Script';
  src: local('Delinda Script Regular'), local('Delinda-Script-Regular'),
      url('./fonts/delinda/DelindaScript-Regular.woff2') format('woff2'),
      url('./fonts/delinda/DelindaScript-Regular.woff') format('woff'),
      url('./fonts/delinda/DelindaScript-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archive Garamond';
  src: local('Archive Garamond Pro'), local('Archive-Garamond-Pro'),
      url('./fonts/garamond/ArchiveGaramondPro.woff2') format('woff2'),
      url('./fonts/garamond/ArchiveGaramondPro.woff') format('woff'),
      url('./fonts/garamond/ArchiveGaramondPro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archive Garamond';
  src: local('Archive Garamond Pro Italic'), local('Archive-Garamond-Pro-Italic'),
      url('./fonts/garamond/ArchiveGaramondPro-Italic.woff2') format('woff2'),
      url('./fonts/garamond/ArchiveGaramondPro-Italic.woff') format('woff'),
      url('./fonts/garamond/ArchiveGaramondPro-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Archive Garamond';
  src: local('Archive Garamond Pro Bold Italic'), local('Archive-Garamond-Pro-Bold-Italic'),
      url('./fonts/garamond/ArchiveGaramondPro-BoldItalic.woff2') format('woff2'),
      url('./fonts/garamond/ArchiveGaramondPro-BoldItalic.woff') format('woff'),
      url('./fonts/garamond/ArchiveGaramondPro-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

.delinda {
  font-family: 'Delinda Script';
  /* letter-spacing: -1px; */
  color: #9c8f86;
}

.react-images__footer {
  font-size: 24px!important;
}

.garamond {
  font-family: 'Archive Garamond';
  /* letter-spacing: -.02rem; */
  color: #9c8f86;
  font-style: italic;
}

.garamond-regular {
  font-family: 'Archive Garamond';
  color: #9c8f86;
  font-style: normal;
  letter-spacing: .5rem;
  font-size: 1rem;
}

.ampersand {
  background: url('ampersand.png');
  background: url('ampersand.svg'),
    linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 2rem;
}

.dots {
  --n:30;
  width: 200px;
  height: 4px;
  margin: 15px auto;
  border: none;
  background:radial-gradient(circle closest-side,#9c8f86 98%,#0000) 0/calc(100%/var(--n)) 100%;
}
.names{
  padding-top: 1rem;
  margin-left: -1rem;
}
.name {
  width: 100%;
  display: block;
}

.name:first-child {
  text-align: center;
}

.name:last-child {
  text-align: center;
}
.password {
  text-align: center;
  border: none;
  font-size: 1.5rem;
  max-width: 75vw;
  border-radius: 4px;
  background: rgb(239,237,236);
}
.signin {
  font-size: 1.25rem;
  background: none;
  border: 1px solid;
  border-radius: 5px;
  margin-top: 1rem!important;
  padding: 0 2rem;
}
.clearfix:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */
.clearLeft{
    clear: left;
}
a{
	color: #08c;
	text-decoration: none;
}
a.download-button {
  background-color: #9c8f86;
  border: 1px #666;
  color: white;
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
}

a:hover {
	text-decoration: underline;
}

a:link {
    text-decoration: none;
}
a:hover {
    color: #ccc;
}
.container {
	margin: 0 auto;
}
footer {
  text-align: center;
  padding: 2rem 0 4rem 0;
}
h1, h2, h3 {
    color: #222;
    font-weight: 200;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    font-size: large;
    padding: 0;
}
h1{
    font-size: 5rem;
    margin-top: 2.5rem;
}
h2{
  margin: 15px 0 5px 0;
  font-weight: 400;
  font-family: 'Delinda Script';
}
h2.header,.sub{
  text-align: center;
}
h3{
  margin: 5px 0 5px 0;
  font-size: 1.75rem;
}
header{
  padding-bottom: 28px;
}
header h3{
    text-align: center;
}
p{
  margin: 1rem 1rem 3rem 1rem;
  font-family: 'Archive Garamond';
  font-size: 1.25rem;
}
hr{
  margin: 1em 10vw 3em 10vw;
  color: #9c8f86;
}
iframe{
    margin: 0 auto;
    margin-top: 10px;
    display: block;
    width: 85px;
}
.react-photo-gallery--gallery{
  margin-bottom: 15px;
}
.App{
    padding: 28px 0;
    margin: 0;
    text-align: center;
}
.loading-msg{
    font-size: 20px;
    color: #666;
    width: 130px;
    margin: 0 auto;
    padding-bottom: 5vh;
}

.loading-msg:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 10px;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}
#react-images-container{
 background-color: rgba(0,0,0,0.9);
 font-size: 24px;
}
.toggle-select {
  background: #06befa;
  background-image: linear-gradient(to bottom, #06befa, #2980b9);
  border-radius: 3px;
  border: 0;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}
.toggle-select:hover {
  background: #06befa;
  background-image: linear-gradient(to bottom, #06befa, #3498db);
  text-decoration: none;
}
@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}
#msg-app-loading{
    margin-top: 60px;
}
#msg-loading-more{
    text-decoration: none;
    display: block;
    margin-top: 10px;
}
@media only screen and (min-width: 825px) {
  .App{
    margin: 0 70px;
  }
  h1 {
    padding: 0;
  }
  .names {
    padding-top: 2rem;
  }
  .name {
    width: 47%;
    display: inline-block;
  }
  .name:first-child {
    text-align: right;
    padding-right: 1rem;
  }
  .name:last-child {
    text-align: left;
    padding-left: 1rem;
  }
}
